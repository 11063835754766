import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Dashboard1 from "../views/dashboards/Dashboard1.js";
import { element, exact } from "prop-types";
import WeFlow from "../views/weflow/weFlow.js";
import MessagesFlow from "../views/weflow/messages.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

/*****Pages******/
//const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable.js"));

// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete.js")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton.js"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox.js"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio.js"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider.js"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch.js"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts.js"));

// orders
const OrdersPanel = lazy(() => import("../views/orders/OrdersPanel.js"));
const LogisticOrdersPanel = lazy(() =>
  import("../views/orders/LogisticOrdersPanel.js")
);
const ClienteOrderSingleView = lazy(() =>
  import("../views/clients/OrderView/OrderView.js")
);
const AllOrders = lazy(() => import("../views/orders/AllOrders.js"));
// orders

//carts
const AllCarts = lazy(() => import("../views/orders/Carts.js"));
//carts

//sessions
const AllSessions = lazy(() => import("../views/orders/CustomerSessions.js"));
const SessionSingleView = lazy(() =>
  import("../views/clients/OrderView/sessionView.js")
);
//sessions

//reviews
const AllReviews = lazy(() => import("../views/orders/AllReviews.js"));
const SingleReview = lazy(() =>
  import("../views/clients/OrderView/OrderReview.js")
);
//reviews

//menu
const ShopMenu = lazy(() => import("../views/shopmenu/ShopMenu.js"));
const ListItems = lazy(() => import("../views/shopmenu/subpages/listItems.js"));
const ListComplements = lazy(() =>
  import("../views/shopmenu/subpages/listComplements.js")
);
const ListComplementsOptions = lazy(() =>
  import("../views/shopmenu/subpages/listComplementsOptions.js")
);
const ListCategories = lazy(() =>
  import("../views/shopmenu/subpages/listCategories.js")
);
//menu

//clients
const ClientsPanel = lazy(() => import("../views/clients/ClientsPanel.js"));
const ClientsSingle = lazy(() => import("../views/clients/ClientsSingle.js"));
//clients

//offers
const Offers = lazy(() => import("../views/offers/offers.js"));

//offers

//loyalty
const Loyalty = lazy(() => import("../views/loyalty/loyaltySettings.js"));
//loyalty

//settings
const Settings = lazy(() => import("../views/settings/Settings.js"));
const CompaniesAndBranches = lazy(() =>
  import("../views/settings/companiesAndBranches/companiesAndBranches.js")
);
const UsersAdmin = lazy(() =>
  import("../views/settings/components/usersAdmin/usersAdmin.js")
);
const UsersAdminAddNew = lazy(() =>
  import("../views/settings/components/usersAdmin/usersAdminAddNew.js")
);

const LabsMap = lazy(() =>
  import("../views/settings/components/deliveryAreas/components/labsMap.js")
);

//ifood integration
const IntegrateIfood = lazy(() =>
  import("../views/settings/integrateIfood.js")
);

//logistic
const DriversList = lazy(() =>
  import("../views/settings/components/deliveryAreas/components/drivers.js")
);

const DriversSingle = lazy(() =>
  import(
    "../views/settings/components/deliveryAreas/components/driverSingle.js"
  )
);

const NewDriver = lazy(() =>
  import("../views/settings/components/deliveryAreas/components/newDriver.js")
);
//logistic

//settings

//delivery areas
const DeliveryAreasMainDialog = lazy(() =>
  import("../views/settings/components/deliveryAreas/deliveryAreas.js")
);
//delivery areas

//working hours
const WorkingHoursDialog = lazy(() =>
  import("../views/settings/components/deliveryAreas/workingHoursDialog.js")
);
//working hours

//payment methods
const PaymentMethodsDialog = lazy(() =>
  import("../views/settings/components/deliveryAreas/paymentMethods.js")
);
//payment methods

//login
const Login = lazy(() => import("../views/login/login.js"));
//login

//branches
const SelectBranch = lazy(() => import("../views/branches/branches.js"));
//branches

//all companies
//MultiStore
const MultiStore = lazy(() => import("../views/multistore/index.js"));
const MultiStoreOrders = lazy(() => import("../views/multistore/orders.js"));
//all companies

//edit product
const EditProductDialog = lazy(() =>
  import("../views/shopmenu/subpages/components/editProductDialog.js")
);
//edit product

//edit complement groups
const EditComplementGroupDialog = lazy(() =>
  import("../views/shopmenu/subpages/components/editComplementGroupDialog.js")
);
//edit complement groups

//edit complement items
const EditComplementItemDialog = lazy(() =>
  import("../views/shopmenu/subpages/components/editComplementItemDialog.js")
);
//edit complement items

//edit category
const EditCategoryDialog = lazy(() =>
  import("../views/shopmenu/subpages/components/editCategoryDialog.js")
);
//edit category

//relacionamento
//const WeFlow = lazy(() => import("../views/weflow/weFlow.js"));
//relacionamento

//offers edit
const OfferDialogEdit = lazy(() =>
  import("../views/offers/offerDialogEdit.js")
);
//offers edit

//reports
const SalesReport = lazy(() => import("../views/reports/SalesReports.js"));
const ProductsReport = lazy(() =>
  import("../views/reports/ProductsReports.js")
);
const ChannelsReport = lazy(() =>
  import("../views/reports/SalesChannelsReports.js")
);
const RealtimeReports = lazy(() =>
  import(
    "../views/settings/components/deliveryAreas/components/realtimeReport.js"
  )
);
const ClientsReports = lazy(() => import("../views/reports/ClientsReports.js"));
const BranchesReports = lazy(() =>
  import("../views/reports/BranchesReports.js")
);
//reports

//delivery
//views\settings\components\deliveryAreas\generalDeliverySettings.js
const Delivery = lazy(() =>
  import(
    "../views/settings/components/deliveryAreas/generalDeliverySettings.js"
  )
);

const DeliveryAreasMap = lazy(() =>
  import("../views/settings/components/deliveryAreas/deliveryAreasMap.js")
);

const DeliveryAllAreasMap = lazy(() =>
  import("../views/settings/components/deliveryAreas/deliveryAllAreasMap.js")
);

//banners and styles
const BannersAndStylesDialog = lazy(() =>
  import("../views/settings/components/bannersAndStyles.js")
);
//banners and styles

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/selecionar-filial",
    element: <SelectBranch />,
  },
  {
    path: "/selecionar-restaurante",
    element: <MultiStore />,
  },
  {
    path: "multistore-orders",
    element: <MultiStoreOrders />,
  },
  {
    path: "/avaliacao/:reviewId",
    element: <SingleReview />,
  },

  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="home" /> },
      { path: "home", exact: true, element: <Dashboard1 /> },
      { path: "pedidos", exact: true, element: <OrdersPanel /> },
      { path: "carrinhos", exact: true, element: <AllCarts /> },
      { path: "pedidos/historico", exact: true, element: <AllOrders /> },
      {
        path: "pedidos/logistica",
        exact: true,
        element: <LogisticOrdersPanel />,
      },
      { path: "pedidos/avaliacoes", exact: true, element: <AllReviews /> },
      { path: "delivery", exact: true, element: <Delivery /> },
      {
        path: "delivery/editar-areas",
        exact: true,
        element: <DeliveryAreasMap />,
      },
      {
        path: "delivery/visualizar-areas",
        exact: true,
        element: <DeliveryAllAreasMap />,
      },
      {
        path: "clientes/sessoes",
        exact: true,
        element: <AllSessions />,
      },
      {
        path: "clientes/sessoes/:sessionId",
        exact: true,
        element: <SessionSingleView />,
      },
      {
        path: "relacionamento/weflow",
        exact: true,
        element: <WeFlow />,
      },
      { path: "relacionamento/mensagens", element: <MessagesFlow /> },
      {
        path: "clientes/pedido/:orderId",
        exact: true,
        element: <ClienteOrderSingleView />,
      },
      { path: "/cardapio", exact: true, element: <ShopMenu /> },
      {
        path: "cardapio/itens/:productId",
        exact: true,
        element: <EditProductDialog />,
      },
      /* { path: "cardapio", exact: true, element: <ShopMenu /> }, */
      { path: "cardapio/itens", exact: true, element: <ListItems /> },
      {
        path: "ofertas/:offerId",
        exact: true,
        element: <OfferDialogEdit />,
      },
      {
        path: "cardapio/categorias/:categoryId",
        exact: true,
        element: <EditCategoryDialog />,
      },
      {
        path: "cardapio/complementos/item/:itemId",
        exact: true,
        element: <EditComplementItemDialog />,
      },
      {
        path: "cardapio/complementos/grupo/:groupId",
        exact: true,
        element: <EditComplementGroupDialog />,
      },
      {
        path: "cardapio/complementos",
        exact: true,
        element: <ListComplements />,
      }, ///pagina de edição do produto
      {
        path: "cardapio/opcoes",
        exact: true,
        element: <ListComplementsOptions />,
      },
      { path: "cardapio/categorias", exact: true, element: <ListCategories /> },
      { path: "clientes/:clientId", exact: true, element: <ClientsSingle /> },
      { path: "clientes", exact: true, element: <ClientsPanel /> },
      { path: "ofertas", exact: true, element: <Offers /> },
      { path: "relatorios", exact: true, element: <SalesReport /> },
      { path: "relatorios/produtos", exact: true, element: <ProductsReport /> },
      { path: "relatorios/canais", exact: true, element: <ChannelsReport /> },
      {
        path: "relatorios/clientes",
        exact: true,
        element: <ClientsReports />,
      },
      {
        path: "relatorios/filiais",
        exact: true,
        element: <BranchesReports />,
      },
      {
        path: "relatorios/realtime",
        exact: true,
        element: <RealtimeReports />,
      },
      { path: "configuracoes", exact: true, element: <Settings /> },
      { path: "fidelidade", exact: true, element: <Loyalty /> },
      {
        path: "configuracoes/utilizadores",
        exact: true,
        element: <UsersAdmin />,
      },
      {
        path: "configuracoes/restaurantes-e-filiais",
        exact: true,
        element: <CompaniesAndBranches />,
      },
      {
        path: "configuracoes/logistica/entregadores",
        exact: true,
        element: <DriversList />,
      },
      {
        path: "configuracoes/logistica/entregador/:driverId",
        exact: true,
        element: <DriversSingle />,
      },
      {
        path: "configuracoes/logistica/entregadores/novo",
        exact: true,
        element: <NewDriver />,
      },
      {
        path: "configuracoes/labs/track",
        exact: true,
        element: <LabsMap />,
      },
      {
        path: "configuracoes/labs/realtime",
        exact: true,
        element: <RealtimeReports />,
      },
      {
        path: "configuracoes/labs/integrate-ifood",
        exact: true,
        element: <IntegrateIfood />,
      },
      {
        path: "configuracoes/utilizadores/novo",
        exact: true,
        element: <UsersAdminAddNew />,
      },
      {
        path: "configuracoes/banners-e-estilos",
        exact: true,
        element: <BannersAndStylesDialog />,
      },
      {
        path: "configuracoes/pagamentos",
        exact: true,
        element: <PaymentMethodsDialog />,
      },
      {
        path: "configuracoes/modalidades",
        exact: true,
        element: <DeliveryAreasMainDialog />,
      },
      {
        path: "configuracoes/horarios",
        exact: true,
        element: <WorkingHoursDialog />,
      },
      { path: "tables/basic-table", element: <BasicTable /> },
      { path: "/form-layouts/form-layouts", element: <FormLayouts /> },
      { path: "/form-elements/autocomplete", element: <ExAutoComplete /> },
      { path: "/form-elements/button", element: <ExButton /> },
      { path: "/form-elements/checkbox", element: <ExCheckbox /> },
      { path: "/form-elements/radio", element: <ExRadio /> },
      { path: "/form-elements/slider", element: <ExSlider /> },
      { path: "/form-elements/switch", element: <ExSwitch /> },
    ],
  },
];

export default ThemeRoutes;
