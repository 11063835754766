import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { reducer } from "./reducer";
import { useNavigate } from "react-router-dom";
import client, { socket } from "../services/feathers";
import api from "../services/rest";
import locations from "../services/locations";
import Sound from "../assets/adrop.mp3";
import { v4 as uuidv4 } from "uuid";
import qz from "qz-tray";
import currency from "currency-formatter";
import calculateTotalPrice from "../utils/calculateTotalCart";
import moment from "moment";
import jsRsaSign from "jsrsasign";

const AppContext = createContext();

const initialState = {
  user:
    typeof window !== "undefined"
      ? localStorage.getItem("currentUser")
        ? JSON.parse(localStorage.getItem("currentUser"))
        : null
      : null,
  company: null,
  companies: [],
  filterPayload:
    typeof window !== "undefined"
      ? localStorage.getItem("filterPayload")
        ? JSON.parse(localStorage.getItem("filterPayload"))
        : null
      : null,
  branches: [],
  currentBranch: null,
  currentBranchIfoodMerchant: null,
  recoveringCart: null,
  orders: [],
  categories: [],
  products: [],
  complementGroups: [],
  complements: [],
  offers: [],
  coupons: [],
  users: [],
};

export const AppProvider = ({ children }) => {
  //DISPONIBILIZO O PROVIDER EM TODO O APP (STATE E DISPATCH)
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef(state);

  const navigate = useNavigate();

  let listening = false;

  const getData = async (location, payload) => {
    //console.log("location", location);
    //console.log("payload", payload);
    try {
      let response = await client.service(location).find({ query: payload });
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const getSingleData = async (location, payload) => {
    try {
      let response = await client.service(location).get(payload);
      return response;
    } catch (e) {
      //console.log(e);
    }
  };

  const postData = async (location, payload) => {
    let response = await client.service(location).create(payload);
    return response;
  };

  const postMultiFormData = async (location, payload) => {
    const { accessToken } = await client.get("authentication");

    let response = await api.post(location, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  };

  const patchData = async (location, id, payload) => {
    //console.log("trying to patch");

    //console.log(payload);

    let response = await client.service(`${location}`).patch(id, payload);
    return response;
  };

  const removeData = async (location, id) => {
    let response = await client.service(location).remove(id);
    return response;
  };

  const playSound = () => {
    const audio = new Audio(Sound);
    audio.play();
  };

  useEffect(() => {
    const certificate = "/static/images/digital-certificate-dashboard.txt";

    const privateKey =
      "-----BEGIN PRIVATE KEY-----\n" +
      "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCgdgEEHMh8rnzP\n" +
      "tTzfZPZLS9lL04wUgeAXV019N4aTIqCi3RN6IgD/MUFnl5LoHrj1VfgVp54mN10/\n" +
      "RTII8RWO9pDAq4TKjhTbN+uOaUeoENvUfSmfwZ6eVwvcRDk+Db+DEtPRwsEp2b1U\n" +
      "sRHljjJWHY90XY9xyT+XRtUgWVYagNQNOSGXsS68LTSJHrluzoiUUKcYQCQtkgNj\n" +
      "Ia9r3+nedmnArih+MmSvNEpg+PCkowWAWVppq7m8E4h5RTOGvFN9CHuOmtdPql0U\n" +
      "Lye2OBLwS8zk3YpxTXf1jeA3kEN6IIjUrJ/3o0YTikwwOZDdYxC//zIF8aQEIrqx\n" +
      "Xt5xst7rAgMBAAECggEAP71AQBLy7NwfBh8XNCIV1+kkQwOghFDm5D6wsEnLMk0y\n" +
      "BB/PaddyD+zP70d9eqkhjYr9xhRUNRgO+WASNQ0XJDf7Xh2D3TY9QKtg7awSY8d2\n" +
      "m1ywmR4/VOR8+J09I9IwPe/PpmY3dtlgZPEFi+tFQYHyhyXXIjrwALaJWJVEJ7hd\n" +
      "9DUgvE4x9g5U3SmKvIjfXasQwWyc/8j9z84/ipctMx3ozdg8DiTvA2kdttY+CR8h\n" +
      "HbPnU/u7VCjEQvKR23HVfYwO63mL/BVL+0BY/REgaX6QeF72bqgMGwsI5EoBrSGL\n" +
      "eyX66pN4B6dNPMMQsVNYyIjUiPK5TWMcVGzGF8MjyQKBgQDPPZmnQYigRCrI0kK0\n" +
      "RLDmUUPKIX4CglLH3NnpYqq7GPMcmyswfVEJGJVaPBFY0dx/vFACrD5cpk2rTuhh\n" +
      "UCwc8XkbN2ASa1O9Wb8KehCi6uaEISkOsFr4tPIulRRTMa1Pd2xHCjlX1fttE34H\n" +
      "w/aUzan5aF77koQyuVcVGcMddwKBgQDGNswLbZpCujT08DccSGC4WlUHPm6txnJw\n" +
      "vxG5a8N6XrNmDD0Z/CF4xXUVsIiNRy9T1P/X1aCVio6BjEcy5c1rIcOWqqJaPVSR\n" +
      "bLqhpCxrJbWWG2C2WLIeTSKIqhith9r+KKL9Aa/hpG9JbutfNDrfSZxMWpfbh1Z5\n" +
      "BriNL4cXLQKBgBjxgRzwdlFT4xJovD6WRfRys4YQoeoLZYtIubGtfn8mcZxv0LL1\n" +
      "YluF6fQCZtQZ7kAxmi+4K4lbOt3zkWSlEaoqJjlwYM/Wuy1/L89DAd2aCES++QIb\n" +
      "l9d3b+7LYrbgs8tsxEuIn35C8xAd2H12yKcAWgT62vPMnry8Mg8IGsPhAoGASmVK\n" +
      "1IdAvQbS1ZRLuwvXcZpGC7/9HrA7R+IPLv8pkYZ14zl4vYYKsh+6wHdP6WbsdpSd\n" +
      "qk7+ROKKdklGd+4U2b8EgEpvQVt/oE2lBwJtwZGXxE1mUUqdIA5nhdACDy/MnFR4\n" +
      "SGoXr6NeObuuED+0URZ52ZavJLB5XleNcUCDfckCgYBEYeGEZo/gxUKQicJxr4kr\n" +
      "67kXQr4c1hz194q7xdfPpAnOdP3Xf7rPVET2HMjrUA69hLCBIoPiFD7hztR44jHH\n" +
      "vUIbNTVs3c3JeApv58aS3kj1QBW0DzhP8HStevbijwJB6oTUqPLbqxABw0AnSeJx\n" +
      "/WCTc0pJORRlZ3yi1NHi3g==\n" +
      "-----END PRIVATE KEY-----";

    qz.security.setCertificatePromise(function (resolve, reject) {
      fetch(certificate, {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      })
        .then(function (data) {
          console.log("qz security", data);
          data.ok ? resolve(data.text()) : reject(data.text());
        })
        .catch((e) => {
          console.log("certificate error", e);
        });
    });

    qz.security.setSignaturePromise(function (toSign) {
      return (resolve, reject) => {
        try {
          const pk = jsRsaSign.KEYUTIL.getKey(privateKey);
          const sig = new jsRsaSign.KJUR.crypto.Signature({
            alg: "SHA1withRSA",
          });
          sig.init(pk);
          sig.updateString(toSign);
          const hex = sig.sign();
          resolve(jsRsaSign.stob64(jsRsaSign.hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });
  }, []);

  const listenStatus = () => {
    //console.log("chamando o listener");

    const listen = socket;

    listen.on("manager/companies created", (res) => {
      //console.log("resultado do on");
      //console.log(res);
    });

    listen.on("manager/orders created", (res) => {
      //CHECAR O CERTIFICADO//

      //USAR REF AQUI
      //console.log("orders created");
      //CHECK COMPANY BEFORE PLAYING

      const translateBranch = (branch) => {
        const branchTitle = state.branches.filter((b) => b._id === branch)
          .length
          ? state.branches.filter((b) => b._id === branch)[0].name
          : " - ";
        return branchTitle;
      };

      const currentCompany = state.company;
      //const currentUser = stateRef.current.user.user;
      //console.log(currentUser);

      //console.log("currentCompany", currentCompany);

      if (currentCompany && res.company === currentCompany._id) {
        //console.log("order IS from the current company");
        playSound();
      }
      //console.log("state when created", state);

      //if (res.branch === state.currentBranch._id) {
      if (
        state.orders.length &&
        res.company.toString() === state.company._id.toString()
      ) {
        let previousOrders = [res, ...state.orders];

        //CHECK FOR THE BRANCH
        const currentBranch = stateRef.current.currentBranch;

        if (res.branch === currentBranch._id.toString()) {
          console.log(
            "new order sent to this branch - settings:",
            currentBranch.settings
          );

          if (
            currentBranch.settings &&
            currentBranch.settings.allowPrinting &&
            currentBranch.settings.printerName
          ) {
            console.log("trying to print");

            const cartSubtotal = calculateTotalPrice(res.items);
            const total = cartSubtotal + res.deliveryFee;
            const benefits = (res.ifoodTotals && res.ifoodTotals.benefits) || 0;

            let orderHtml = `<p>Pedido #${res.seq
              .toString()
              .padStart(4, "0")}</p><p><strong>${translateBranch(
              res.branch
            )}</strong><br /><strong>Horário:</strong> ${moment(
              res.createdAt
            ).format("DD/MM/YY H:m")}<br /><strong>Cliente:</strong> ${
              res.customer.name
            }<br /><strong>WhatsApp:</strong> ${
              res.customer.phone
            }<br /><strong>Tipo de pedido:</strong> ${
              res.modality === 1 ? "Delivery" : "Retirada"
            }</p>`;

            if (res.modality === 1) {
              orderHtml += "=========================<br />";
              orderHtml += `<strong style="text-align: center;">Endereço de entrega</strong><br />'=========================<br />
              Endereço: ${res.address.street}, ${res.address.number}
                          <br />
                          ${
                            res.address.complement &&
                            res.address.complement !== ""
                              ? `Complemento: ${res.address.complement}<br/>`
                              : ""
                          }
                          ${
                            res.address.reference &&
                            `Referência: ${res.address.reference}<br />`
                          }
                          Bairro: ${res.address.neighborhood}<br />Cidade: ${
                res.address.city
              }<br />CEP: ${res.address.postalCode}<br />`;
            }

            orderHtml += "=========================<br />";
            orderHtml += `<strong style="text-align: center;">Itens do pedido</strong><br />`;
            orderHtml += "=========================<br />";

            res.items.map((item) => {
              orderHtml += `<p><strong>${item.quantity}x - ${
                item.name
              } (${currency.format(item.quantity * item.price, {
                code: "BRL",
              })})</strong></p>`;
              item.complements &&
                item.complements.length > 0 &&
                item.complements.map((complement) => {
                  orderHtml += `<p>${complement.name}</p>`;
                  complement.items.map((item) => {
                    orderHtml += `<span>---${item.quantity}x - ${
                      item.name
                    } (${currency.format(item.quantity * item.price, {
                      code: "BRL",
                    })})</span><br/>`;
                  });
                });
              orderHtml +=
                item.notes && item.notes !== "" && `<br />Obs: ${item.notes}`;
            });

            orderHtml += "=========================<br />";
            orderHtml += `<strong style="text-align: center;">Forma de Pagamento</strong><br />`;
            orderHtml += "=========================<br /><br />";

            orderHtml += `${
              res.paymentMethod &&
              res.paymentMethod.type &&
              res.paymentMethod.type === "cash"
                ? "Dinheiro na Entrega<br />"
                : ""
            }`;

            if (
              res.paymentMethod.type === "cash" &&
              res.paymentMethod.change &&
              res.paymentMethod.change !== 0 &&
              res.paymentMethod.change !== "" &&
              res.paymentMethod.change !== "0"
            ) {
              orderHtml += `Levar troco para: ${currency.format(
                res.paymentMethod.change,
                {
                  code: "BRL",
                }
              )}<br />Valor do troco: ${currency.format(
                res.paymentMethod.change - total,
                {
                  code: "BRL",
                }
              )}<br /><br />`;
            }

            if (res.paymentMethod.type === "card") {
              orderHtml += `Cartão: ${res.paymentMethod.selectedCardType}<br/><br/>`;
            }

            if (res.notes && res.notes !== "") {
              orderHtml += "=========================<br />";
              orderHtml += `<strong style="text-align: center;">Observações do pedido</strong><br />`;
              orderHtml += "=========================<br /><br />";

              orderHtml += `${res.notes}<br />`;
            }

            orderHtml += `<p><strong>Subtotal:</strong> ${currency.format(
              cartSubtotal,
              {
                code: "BRL",
              }
            )}<br /><strong>Taxa de entrega:</strong>${currency.format(
              res.deliveryFee,
              {
                code: "BRL",
              }
            )}<br/ ><strong>Total:</strong>${currency.format(total, {
              code: "BRL",
            })}</p>`;

            orderHtml += `<br /><br /><br /><p>Feito na plataforma WeDo</p>`;

            qz.websocket
              .connect()
              .then(() => {
                return qz.printers.find(
                  currentBranch &&
                    currentBranch.settings &&
                    currentBranch.settings.printerName
                );
              })
              .then((printers) => {
                console.log("printers", printers);

                let config = qz.configs.create(
                  currentBranch &&
                    currentBranch.settings &&
                    currentBranch.settings.printerName
                );
                return qz.print(config, [
                  {
                    type: "pixel",
                    format: "html",
                    flavor: "plain",
                    data: orderHtml,
                  },
                ]);
              })
              .then(() => {
                return qz.websocket.disconnect();
              })
              .then(() => {
                // process.exit(0);
              })
              .catch((err) => {
                console.error(err);
                // process.exit(1);
              });
          }
        } else {
          console.log("order sent to another branch");
        }

        //console.log("state.orders", state.orders);
        //console.log("previousOrders", previousOrders);

        ///previousOrders.push(res);

        //CHECK IF THE USER IS A MULTISTORE USER
        setOrders(previousOrders);
        setMultiStoreOrders(previousOrders); //AQUI
      }
      //}
    });

    listen.on("manager/orders patched", (res) => {
      //console.log("order patched");

      //console.log("state when patched", state);
      //USAR O REF

      if (
        state.orders &&
        state.orders.length &&
        res.company.toString() === state.company._id.toString()
      ) {
        const finalPayload = [
          res,
          ...state.orders.filter((o) => o._id !== res._id),
        ];
        dispatch({ type: "SET_ORDERS", payload: finalPayload });
      }
    });

    listen.on("manager/branches patched", (res) => {
      //console.log("resultado do patch na branch on listen");
      //console.log(res);
      if (
        res._id === stateRef.current.currentBranch &&
        stateRef.current.currentBranch._id
      ) {
        setBranch(res);
      } else {
        //console.log("different branches");
        //console.log("currentBranch", stateRef.current.currentBranch._id);
        //console.log("patched branch", res._id);
      }
    });
  };

  useEffect(() => {
    if (state.currentBranch && state.company) {
      localStorage.setItem(
        "filterPayload",
        JSON.stringify({
          branch: state.currentBranch._id,
          company: state.company._id,
        })
      );
    }
  }, [state.currentBranch, state.company]);

  const authUser = async (email, password) => {
    const payload = {
      strategy: "local",
      email: email,
      password: password,
    };

    let response = await client.authenticate(payload);

    //CREATE THE ACTIVITY SESSION AND PATCH THE CURRENT USER

    const sessionUUID = uuidv4();

    postData(locations.usersActivities, {
      user: response.user._id,
      session: sessionUUID,
      action: "LOGIN",
    }).then((res) => {
      patchData(locations.users, response.user._id, {
        currentSession: sessionUUID,
      });
    });

    return response;
  };

  useEffect(() => {
    //VERIFICAR SE ISSO AQUI NÃO CAUSA LOOP
    if (!listening) {
      listenStatus();
      listening = true;
    }
  }, [state.orders]);

  const populateCompanyAndBranch = async (user) => {
    const company = await getCompany(user.company);

    if (company) {
      dispatch({ type: "SET_COMPANY", payload: company });
      const branches = await getBranches(company._id, user.allowedBranches);
      if (branches.length > 0) {
        dispatch({ type: "SET_BRANCHES", payload: branches });
        return true;
      }
    }
  };

  const setCompany = (company) => {
    dispatch({ type: "SET_COMPANY", payload: company });
    stateRef.current.company = company;
  };

  const populateAllCompaniesAndBranches = async (user) => {
    //console.log("populateAllCompaniesAndBranches");

    //console.log("user.allowedCompanies", user.allowedCompanies);
    const companies = await getCompanies(user.allowedCompanies);

    //console.log("companies", companies);

    if (companies) {
      dispatch({ type: "SET_ALLOWEDCOMPANIES", payload: companies });
      const branches = await getCompaniesBranches(user.allowedBranches); //ERRO AQUI

      //console.log("allowed branches", branches);

      if (branches.length > 0) {
        dispatch({ type: "SET_BRANCHES", payload: branches });
        return true;
      }
    } else {
      //console.log("no companies");
    }
  };

  //PRODUCT FUNCTIONS
  const getProductCategories = async (branch) => {
    const response = await getData("manager/product-categories", {
      branch: branch,
    });
    return response.data;
  };
  const getPDVUsers = async (branch) => {
    const response = await getData(locations.users, {
      $limit: 1000,
    });
    return response.data;
  };
  const getProducts = async (branch) => {
    const response = await getData("manager/products", {
      branch: branch,
      $limit: 1000,
    });
    return response.data;
  };
  const getActiveProducts = async (branch) => {
    const response = await getData("manager/products", {
      branch: branch,
      active: true,
      $limit: 1000,
    });
    return response.data;
  };
  const getComplementGroups = async (branch) => {
    const response = await getData("manager/complementGroups", {
      branch: branch,
    });
    return response.data;
  };
  const getComplements = async (branch) => {
    const response = await getData("manager/complements", {
      branch: branch,
      $limit: 1000,
    });
    return response.data;
  };
  const getOffers = async (branch) => {
    const response = await getData("manager/offers", {
      branch: branch,
      active: true,
    });
    return response.data;
  };

  //OFFERS
  const calculateOfferPrice = (product, offer) => {
    //CALCULAR O PREÇO DE PRODUTO EM OFERTA
    //JA NO MAP DAS CATEGORIAS, SE ELA FOR CATEGORIA, CALCULAR O OFFERPRICE DE CADA UM LA NA LISTA
    //MAS É NECESSÁRIO UM DISPATCH

    let offerPrice = 0;
    let newProduct = product;

    const productPrice = newProduct.price;

    if (offer.reward && offer.reward.type === 0) {
      //VALOR FIXO
      offerPrice = productPrice - offer.reward.amount;
    }

    if (offer.reward && offer.reward.type === 1) {
      //PORCENTAGEM
      offerPrice = productPrice * (1 - offer.reward.amount / 100);
    }

    return offerPrice;
  };

  const applyOffers = (products, categories, offers) => {
    let appliedProducts = [];

    let appliedCategories = [];

    //HANDLE COUPONS
    const filteredCoupons = offers.filter((o) => o.rules.isCoupon);
    dispatch({ type: "SET_COUPONS", payload: filteredCoupons });

    //HANDLE PRODUCTS AND CATEGORIES
    offers.map((offer) => {
      if (offer.matches.type === 0) {
        //APPLIES TO PPRODUCTS
        const matchProducts = products
          .filter((p) => offer.matches.items.includes(p._id))
          .map((product) => {
            return {
              ...product,
              offer: offer,
              offerPrice: calculateOfferPrice(product, offer),
            };
          });
        if (matchProducts.length) {
          appliedProducts.push(matchProducts[0]);
          //console.log("matched products", matchProducts);
        }
      }

      if (offer.matches.type === 1) {
        //APPLIES TO CATEGORIES
        const matchCategories = categories
          .filter((c) => offer.matches.items.includes(c._id))
          .map((category) => {
            return {
              ...category,
              offer: offer,
            };
          });
        appliedCategories.push(matchCategories[0]);
        console.log("matched categories", matchCategories);
      }
    });

    //FILTER PRODUCTS AND CATEGORIES WHO ARE NOT OFFER - THEN PUSH THE APPLIED ALONG WITH THEM TO A FINAL ARRAY
    const filteredProducts = products.filter(
      (item) => !appliedProducts.map((obj) => obj._id).includes(item._id)
    );

    const filteredCategories = categories.filter(
      (item) => !appliedCategories.map((obj) => obj._id).includes(item._id)
    );

    const finalProducts = [...appliedProducts, ...filteredProducts];
    const finalCategories = [...appliedCategories, ...filteredCategories];

    let appliedFromCategory = [];

    const categoryProducts = finalCategories.flatMap((c) => {
      //console.log("category map", c);
      return finalProducts
        .filter((p) => c.items.includes(p._id))
        .map((fp) => {
          const prod = c.offer
            ? {
                ...fp,
                offer: c.offer,
                offerPrice: calculateOfferPrice(fp, c.offer),
              }
            : fp;
          return prod;
        });
    });

    /* console.log(
      "applied finalProducts products",
      finalProducts.filter((p) => p.offer)
    ); */

    //DISPATCH UPDATED PRODUCTS AND CATEGORIES
    dispatch({ type: "SET_PRODUCTS", payload: categoryProducts });
    dispatch({ type: "SET_CATEGORIES", payload: finalCategories });

    //console.log("finalProducts", finalProducts);
  };
  //OFFERS

  //PRODUCT FUNCTIONS

  const populatePDVProducts = async (branchId) => {
    console.log("calling populatePDVProducts");

    getProductCategories(branchId).then((c) => {
      //SET CATEGORIES
      dispatch({ type: "SET_CATEGORIES", payload: c });
      getActiveProducts(branchId).then((p) => {
        //SET PRODUCTS
        dispatch({ type: "SET_PRODUCTS", payload: p });
        getComplementGroups(branchId).then((cg) => {
          //SET COMPLEMENT GROUPS
          dispatch({ type: "SET_COMPLEMENTGROUPS", payload: cg });
          getComplements(branchId).then((comp) => {
            //SET COMPLEMENTS
            dispatch({ type: "SET_COMPLEMENTS", payload: comp });
            console.log("all main products and their complements loaded");
            getOffers(branchId).then((offers) => {
              //console.log("offers", offers);
              dispatch({ type: "SET_OFFERS", payload: offers });

              //APLICAR OFERTAS
              applyOffers(p, c, offers);
              return true;
            });
          });
        });
      });
    });
  };

  const authUserByJwt = async () => {
    //CALL JWT FIRST TO MAKE SURE THE TOKEN IS STILL VALID

    await client.reAuthenticate();

    const response = await client.get("authentication");

    //console.log("response from jwt", response.user);

    const company = await getCompany(response.user.company);
    const companies = await getCompanies(response.user.allowedCompanies);

    if (company) {
      if (response.user.multiStore) {
        dispatch({ type: "SET_ALLOWEDCOMPANIES", payload: companies });
      } else {
        dispatch({ type: "SET_COMPANY", payload: company });
      }

      //HERE IS THE PROBLEM//
      const branches = response.user.multiStore
        ? await getCompaniesBranches(response.user.allowedBranches)
        : await getBranches(company._id, response.user.allowedBranches);
      if (branches.length > 0) {
        dispatch({ type: "SET_BRANCHES", payload: branches });
      }
    }

    //console.log("accesstoken from authentication");
    //console.log(response.accessToken);

    const sessionUUID = uuidv4();

    //
    postData(locations.usersActivities, {
      user: response.user._id,
      session: sessionUUID,
      action: "LOGIN",
    }).then((res) => {
      patchData(locations.users, response.user._id, {
        currentSession: sessionUUID,
      });
    });

    const payload = {
      accessToken: getToken(),
      strategy: "jwt",
    };

    /* client
      .authenticate(payload)
      .then((res) => {
        console.log("login response from jwt");
        console.log(res);
      })
      .catch((e) => {
        console.log("erro no login jwt");
        console.log(e);
        setUser(null);
      }); */
  };

  const createCompany = () => {
    const payload = {
      name: "Teste WebSocket",
    };

    client.service("manager/companies").create(payload);
  };

  const getCompany = async (id) => {
    //console.log("getting single company");
    const response = await getData(locations.companies, {
      _id: id,
    });
    return response.data.length > 0 ? response.data[0] : null;
  };

  const getCompanies = async (allowedCompanies) => {
    console.log("getting multiple companies");
    const response = await getData(locations.companies, {
      _id: {
        $in: allowedCompanies,
      },
      $limit: 100,
    });
    return response.data.length > 0 ? response.data : null;
  };

  const getAllCompanies = async () => {
    //console.log("getting multiple companies");
    const response = await getData(locations.companies, {
      $limit: 100,
    });
    return response.data.length > 0 ? response.data : null;
  };

  const updateAdminCompanies = async () => {
    const companies = await getAllCompanies();
    dispatch({ type: "SET_ALLOWEDCOMPANIES", payload: companies });
  };

  const getBranches = async (id, allowedBranches) => {
    //console.log("getting branches");
    const response = await getData(locations.branches, {
      company: id,
      _id: {
        $in: allowedBranches,
      },
      $limit: 1000,
    });

    //console.log("getBranches", response.data);

    return response.data.length > 0 ? response.data : [];
  };

  const getCompaniesBranches = async (allowedBranches) => {
    //console.log("getting companies and branches", allowedBranches);
    const response = await getData(locations.branches, {
      _id: {
        $in: allowedBranches,
      },
      $limit: 1000,
    });
    return response.data.length > 0 ? response.data : [];
  };

  const setBranch = (branch) => {
    //console.log("setando a branch");
    dispatch({ type: "SET_CURRENTBRANCH", payload: branch });
    stateRef.current.currentBranch = branch;
  };

  const setOrders = (orders) => {
    //console.log("setando as orders");

    const filters =
      typeof window !== "undefined"
        ? localStorage.getItem("filterPayload")
          ? JSON.parse(localStorage.getItem("filterPayload"))
          : null
        : null;

    //console.log("filters when SET_ORDERS filterPayload", filters);
    //console.log("orders", orders);

    //console.log("stateRef.current", stateRef.current);

    //FILTRO AQUI
    dispatch({
      type: "SET_ORDERS",
      payload:
        (orders &&
          orders.length &&
          orders.filter(
            (o) => o.company.toString() === stateRef.current.company._id
          )) ||
        [],
    });
  };

  const setMultiStoreOrders = (orders) => {
    //console.log("setando as orders");

    const filters =
      typeof window !== "undefined"
        ? localStorage.getItem("filterPayload")
          ? JSON.parse(localStorage.getItem("filterPayload"))
          : null
        : null;

    //console.log("filters when SET_ORDERS filterPayload", filters);
    //console.log("orders", orders);

    //console.log("stateRef.current", stateRef.current);

    //FILTRO AQUI
    dispatch({
      type: "SET_ORDERS",
      payload: orders.length ? orders : [],
    });
  };

  const setRecoveringCart = (cart) => {
    //console.log("setting recovering cart", cart);
    dispatch({ type: "SET_RECOVERINGCARTS", payload: cart });
  };

  useEffect(() => {
    //console.log("currentBranch");
    //console.log(state.currentBranch);
  }, [state.currentBranch]);

  useEffect(() => {
    //console.log("app loaded");
    //authUser();
    //listenStatus();
    authUserByJwt()
      .then(() => {
        getPDVUsers().then((res) => {
          //console.log("pdvUsers", res);
          dispatch({ type: "SET_USERS", payload: res });
        });
      })
      .catch((e) => {
        //console.log(e);
      });
    //createCompany();
  }, []);

  function setUser(user) {
    dispatch({ type: "SET_USER", payload: user });
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    dispatch({ type: "SET_USER", payload: null });
  }

  function isLogged() {
    if (
      state.user !== null ||
      (typeof window !== "undefined" &&
        localStorage.getItem("token") &&
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== null)
    ) {
      return true;
    }
  }

  function getToken() {
    if (typeof window !== "undefined" && localStorage.getItem("token")) {
      return localStorage.getItem("token");
    }
  }

  useEffect(() => {
    if (state.user !== null) {
      localStorage.setItem("currentUser", JSON.stringify(state.user));
    } else {
      //console.log("user not found");
    }
  }, [state.user]);

  return (
    <AppContext.Provider
      value={{
        state,
        getData,
        getSingleData,
        postData,
        postMultiFormData,
        patchData,
        removeData,
        setUser,
        logout,
        isLogged,
        getToken,
        setBranch,
        setOrders,
        setMultiStoreOrders,
        updateAdminCompanies,
        populateAllCompaniesAndBranches,
        setRecoveringCart,
        authUser,
        populateCompanyAndBranch,
        populatePDVProducts,
        setCompany,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  //USO/LEIO OS DADOS EM USEAPP()
  const context = useContext(AppContext);
  return context;
}
