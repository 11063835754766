const calculateTotalPrice = (cart) => {
  let totalPrice = 0;

  cart.forEach((item) => {
    //console.log("o item no carrinho", item);

    if (item.isOffer) {
      totalPrice += item.offerPrice * item.quantity;
    } else {
      totalPrice += item.originalPrice * item.quantity;
    }

    item.complements &&
      item.complements.forEach((cg) => {
        cg.items.forEach((cgItems) => {
          totalPrice += cgItems.price * cgItems.quantity * item.quantity;
        });
      });
  });

  //console.log(totalPrice);

  return totalPrice;
};
export default calculateTotalPrice;
